<template>
  <div v-if="user" class="container">
    <div class="row justify-content-center">
      <div v-if="postsDataArray === undefined">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-if="message.success !== undefined" class="messages-banner">
        <div class="alert alert-success fixed-top text-center message-banner" role="alert">
          {{ message.success }}
        </div>
      </div>
      <div v-if="message.error !== undefined" class="messages-banner">
        <div class="alert alert-error fixed-top text-center message-banner" role="alert">
          {{ message.error }}
        </div>
      </div>
      <div v-if="postsDataArray === ''">
        <div class="spinner-border" role="status">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header d-flex justify-content-between">
                {{ element.title }}
              </div>
              <div class="editor-box">
                <div class="card-body">
                  <Editor
                    id="editor-1"
                    api-key="lspodsi6g4j8d39md471b78kif3sej01zbno3rcqui5oknyo"
                    :init="{
                      height: 500,
                      width: 1070,
                      menubar: false,
                      statusbar: false,
                      fontsize_formats: '70pt 80pt 90pt 102pt 140pt',
                      language: 'de',
                      toolbar:
                        'myFullscreen | undo redo | bold italic | bullist numlist | outdent indent | fontsizeselect',
                      setup(editor) {
                        editor.ui.registry.addButton('myFullscreen', {
                          text: 'Vollbild',
                          onAction() {
                            setEditorToFullscreen(-1);
                          },
                        });
                      },
                    }"
                    v-model="element.data"
                  ></Editor>
                  <button
                    id="button-1"
                    class="btn btn-success"
                    v-on:click="saveData(index)"
                  >
                    Speichern
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="postsDataArray !== undefined || postsDataArray !== ''"
        class=""
      >
        <div
          class="d-flex justify-content-between"
          v-for="(element, index) in postsDataArray"
          :key="`${index}`"
        >
          <div v-if="element.editable !== false">
            <div class="col-md-12 p-4">
              <div class="card">
                <div class="card-header d-flex justify-content-between">
                  {{ element.title }}
                </div>
                <div class="card-body">
                  <Editor
                    :id="'editor' + index"
                    api-key="lspodsi6g4j8d39md471b78kif3sej01zbno3rcqui5oknyo"
                    :init="{
                      height: 300,
                      width: 1070,
                      menubar: false,
                      statusbar: false,
                      fontsize_formats: '70pt 80pt 90pt 102pt 140pt',
                      language: 'de',
                      toolbar:
                        'myFullscreen | undo redo | bold italic | bullist numlist | outdent indent | fontsizeselect',
                      setup(editor) {
                        editor.ui.registry.addButton('myFullscreen', {
                          text: 'Vollbild',
                          onAction() {
                            setEditorToFullscreen(index);
                          },
                        });
                      },
                    }"
                    v-model="element.data"
                  ></Editor>
                  <button
                    class="btn btn-success"
                    :id="'button' + index"
                    v-on:click="saveData(index)"
                  >
                    Speichern
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ref, onValue, update, getDatabase } from "firebase/database";
import Editor from "@tinymce/tinymce-vue";
import "moment-timezone/builds/moment-timezone-with-data-1970-2030";
import moment from "moment-timezone";

export default {
  components: {
    Editor: Editor,
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  data() {
    return {
      postsDataArray: [],
      message: {
        success: undefined,
        error: undefined,
      },
      editorFullscreen: null,
    };
  },
  methods: {
    loadPostsData() {
      onValue(ref(getDatabase(), `/posts`), (snapshot) => {
        this.postsDataArray = snapshot.val();
        return null;
      });
    },
    saveData(index) {
      moment.locale("de");
      const time = new Date();
      this.postsDataArray[index].date = moment(time)
        .tz("Europe/Berlin")
        .format("LLL:ss");

      update(ref(getDatabase(), `/posts/` + index), this.postsDataArray[index])
        .then(() => {
          this.message.success = "Die Daten wurden erfolgreich gespeichert und werden in ungefähr zwei Minuten aktualisiert.";
          setTimeout(this.resetMessage, 3000);
        })
        .catch((error) => {
          this.message.error = "Es ist ein Fehler aufgetreten.<br>" + error;
          setTimeout(this.resetMessage, 3000);
        });
    },
    resetMessage() {
      this.message.success = undefined;
      this.message.error = undefined;
    },
    setEditorToFullscreen(index) {
      let buttonStyle = document.getElementById("button" + index).style;
      let editorStyle = document.getElementById("editor" + index)
        .nextElementSibling.style;
      if (this.editorFullscreen) {
        const recentEditorStyle = document.getElementById(this.editorFullscreen)
          .nextElementSibling.style;
        recentEditorStyle.height = "300px";
        recentEditorStyle.width = "1070px";
        recentEditorStyle.position = "unset";
        recentEditorStyle.zIndex = "unset";
        buttonStyle.position = "unset";
        buttonStyle.padding = "6px 12px";
      }
      if ("editor" + index === this.editorFullscreen) {
        this.editorFullscreen = null;
        document
          .getElementById("editor" + index)
          .scrollIntoView({ block: "start" });
      } else {
        editorStyle.height = "calc(100vh)";
        editorStyle.width = "100vw";
        editorStyle.position = "fixed";
        editorStyle.left = "0";
        editorStyle.top = "0";
        editorStyle.zIndex = "2";
        buttonStyle.position = "fixed";
        buttonStyle.zIndex = "4";
        buttonStyle.top = "-7px";
        buttonStyle.right = "20px";
        buttonStyle.padding = "3px 10px";
        this.editorFullscreen = "editor" + index;
      }
      //scroll to top wieder herstellen
    },
  },
  beforeMount() {
    this.loadPostsData();
  },
};
</script>
<style scoped>
button {
  margin: 10px 0;
}
.message-banner {
  margin-top: 60px;
}
.expand {
  border: none;
  color: #90979e;
  height: 38px;
  font-size: 38px;
  padding: none;
  overflow: clip;
  align-self: center;
  position: relative;
  left: 936px;
}
.expand span {
  bottom: 19px;
  position: relative;
}
.expand:hover {
  color: #222f3ecb;
}
.topBorder {
  border-top: 1px solid rgb(196, 196, 196);
}
</style>
